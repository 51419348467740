import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet';

import {StripeProvider} from 'react-stripe-elements';
import {Stripe} from 'stripe.js'
import Checkout from '../../components/checkout';

import TingLiu from '../../pics/students/ting_profile.jpg'

const TingStory = () => (


	<div className="white grid-1-3"
	>
	<Helmet

	title="Student's story: Ting Liu, a junior developer at Metro AG"
	meta={[
		{ name: 'description', content: 'Students story: Ting Liu, a junior developer at Metro AG' },
		{ name: 'keywords', content: 'Students story, Barcelona Code School' },
		{ name: 'viewport', content: 'width=device-width, initial-scale=1' }
		]}
		/>
		<div>
		<h1>Student's story: Ting Liu</h1>
		<h2>Graduated from the Web / Mobile Development Bootcamp</h2>
		<h2>Job landed: junior developer at Metro AG, Berlin</h2>
		</div>
		<div style={{
			marginTop: '2em'
		}}>

		<img style={{
			float: 'left',
			width: '50%',
			marginRight: '1em',
		}}
		src={TingLiu} />
		
		<p>"Follow your passion", they say, and passion is what lead Ting to Barcelona Code School’s door. And what a journey it’s been! Originally from China, Ting has been working as a brand manager in Korea, where she launched her first product just five months of starting in a new industry. Not a stranger to challenges, she felt that she needed a career change and face new ones in a different field.</p>

		<p>Like many students in Barcelona Code School, Ting is a newcomer to tech with an educational background in international politics and linguistics, and a career in global marketing and executive training. Her inquisitive mind eventually drove her to sunny Barcelona to fulfill her goal of becoming a qualified JavaScript Full-Stack developer.</p>

		<p><blockquote>"One of the most fascinating things in life is learning new things and taking new challenges, isn't it? I want to prove that even with no background in IT, I can still be a professional programmer."</blockquote></p>

		<p>Ting was not without her doubts. The same motivation that led her to teach herself Korean, a language that she would later do her Master’s in, she conquered programming by putting in the work and extra hours. </p>

		<p><blockquote>"I made an extra studying plan, I spent 1-2 hours a day at home reading some programming-related articles to help myself understand better my code."</blockquote> </p>

		<p>The nine-week Web / Mobile Development Bootcamp may seem a little daunting in the beginning, as it’s intensive program is built around full immersion and practical application, so Ting has some advice on how to deal with that. </p>

		<p><blockquote>"... spend more time on the preparation before starting the bootcamp, like doing some online course, reading some books, anything that could help ... get better prepared." </blockquote></p>

		<p>Once she overcame her initial challenges, Ting really started to enjoy coding so much that she has been using her newly acquired JavaScript skills in building her own portfolio. </p>

		<p>Programming is not just a skill set, it also builds character. It becomes a way of life. Ting explains; </p>

		<p><blockquote>"Don't get frustrated when you get stuck, and don't only focus on the code, it's always more important to understand what you need to do and how you are going to do it, programming is not just about coding, it's about solving problems, writing code is just one small part of it."</blockquote> </p>

		<p>Through her hard work and her awesome philosophy, Ting was not only able to finish the course successfully, but went on to land a job with one of Barcelona Code School’s hiring partners, <strong>Metro AG in Berlin</strong>. </p>

		<p>Though she says she has learned a lot from the JavaScript Bootcamp, technology is a broad ever-changing world. Ting has some important advice for those aspiring to become a programmer. </p>

		<p><blockquote>"You can never learn everything, I think it's more important to always keep learning."</blockquote> </p>

		<p>Ting is only one of the many truly inspirational success stories here in Barcelona Code School. Changing careers doesn't have to be difficult. All it takes is the right attitude and approach. </p>

		</div>
		</div>


		)

		export default TingStory



